/**
 * @file: index.
 * @intro: 全局配置文件.
 */

// 本地存储的前缀
export const storage_prefix = 'zzm_admin_storage_'
// base64加密前缀
export const base64_prefix = 'zzm_admin_base64_'

// 图片地址 根据不同的访问域展示泰国和印尼的图片地址
export const image_base_url = ''
